@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

body, html {
  background-color: #2D2D2D;
  // color: #FFFFFF;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  min-width: 350px;
}

@font-face {
  font-family: "Blair ITC";
  src: url("assets/fonts/blair-itc-bold.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/fonts/Poppins-Regular.ttf");
}

.block {
  display: block;
}

.btn {

  &.dark {
    background-color: #322D31;
    color: white;
    font-family: "Poppins-Medium", sans-serif;
  }

  &.mini {
    height: 18px;
    font-size: 8px;
  }

  &.underlined {
    text-decoration: underline;
  }

  &.yellow {
    background-color: #D8A436;
    color: white;
    font-family: "Poppins-Medium", sans-serif;
  }
}

.container {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: "Poppins-Medium", sans-serif;
}

.ending {
  height: 100px;
}

.row {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dash {
  background-color: #C8984A;
  height: 4px;
  width: 45px;
}

.medium-dash {
  background-color: #C8984A;
  height: 4px;
  width: 70px;
}

.inset {
  &:after, &:before {
    border-top: 100px solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
  }
}

.middle {
  background-color: #E7E3D6;
  font-family: "Poppins-Medium", sans-serif;
  min-width: 350px;
  padding-top: 30px;
  position: relative;

  &:after {
    border-left: 50vw solid #2D2D2D;
    left: 0;
  }

  &:before {
    border-right: 50vw solid #2D2D2D;
    right: 0;
  }
}

.y-center {
  margin: 0 auto;
}

.space-1 {
  letter-spacing: 6px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-dark {
  color: #161618
}

.text-gray {
  color: #7D7D7D
}

.text-yellow {
  color: #D69815
}

.text-font-light {
  font-family: "Poppins-Light", sans-serif;
}

.text-font-medium {
  font-family: "Poppins-Medium", sans-serif;
}

.text-font-semi-bold {
  font-family: "Poppins-SemiBold", sans-serif;
}

.text-font-regular {
  font-family: "Poppins-Regular", sans-serif;
}

.text-dark {
  color: #161618
}

button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 1rem;
  height: 36px;
  letter-spacing: 2px;
  margin: 0;
  padding: 4px 16px;
  text-decoration: none;
  text-align: center;
  transition: background 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: #967630;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}
