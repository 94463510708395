@font-face {
  font-family: blair;
  src: url(../../assets/fonts/blair-itc-bold.woff2);
}
@font-face {
  font-family: poppins;
  src: url(../../assets/fonts/Poppins-Regular.woff2);
}
@font-face {
  font-family: poppins-sb;
  src: url(../../assets/fonts/Poppins-SemiBold.woff2);
}
p{font-family: poppins;letter-spacing: 1px;font-size: 18px
}
h3, h4, a, span, label,input{font-family: poppins-sb;letter-spacing: 1px}
body{
  margin: 0;
      background: #e7e3d6;
}
h4{font-size: 20px;}
header{
      max-height: 120px;
      display: grid;
      grid-template-columns: 30% 70%;
      padding: 0.7% 5%;
      background: white;

    }
header .logo{
  width: 120px;
}
.logo img {
    width: 120px;
    margin-top: 4px;
}
header ul {
    float: right;
}
header li {
      list-style: none;
      display: -webkit-inline-box;
      margin: 0 20px;

    }
header li a{
      text-decoration: none;
      color: black;
      font-family: poppins;
      font-weight: 600;
      text-transform: uppercase;
}
.menu {
    align-self: center;
}

/*body*/
.banner{
  background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("../img/banner.jpg");
  background-size: cover;
  text-align: center;
  display: grid!important;
  grid-template-columns: 50%;
    padding:4%;
    height: 80vh;
}
.banner-content {
  bottom: 5%!important;
    position: absolute;
    width: 55%;
}
.banner img{
  margin: auto;
  display: block;
}
.society {
    width: 300px;
    padding: 8% 0;
}
.coffee{height: 120px;padding: 2%}
.banner h3{
  font-size: 18px;
  font-family:blair;
  color: white;
  letter-spacing: 12px;
    padding-bottom: 1%;
}
hr {
    width: 70px;
    border: solid 2px #D8A436;
}
hr.full {
    width: 70%;
}
.banner p {
    margin: auto;
    color: white;
    padding: 1% 0;
    font-size: 18px;
    padding-bottom: 6%;
    text-align: justify;

}
.content {
    text-align: center;
    margin: auto;
    background: #E7E3D6;
    padding: 10% 0;
}

.plan {
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
}
.plan img {
    width: 40%;
}
.plan p {
    max-width: 80%;
    margin: auto;
    padding: 1% 0;
    font-size: 18px;
}
.plan h3{font-size: 30px}
form.formtwo {
    display: grid!important;
    grid-template-columns: 50% 50%;
    
}
.formtwo .button-submit {
  text-align: center;
  margin-top: 10px;
  margin-left: 60%;

}
.select {
  display: grid;
    grid-template-columns: 25% 50%;
    width: 100%;
    margin: auto;
}
.select div{
  padding: 20px;
}
.select img {
    width: -webkit-fill-available;
        border: solid 5px #d8a436;
}
.select h4 {
    font-size: 20px;
    height: 40px;
}
.radio form {
    text-align: initial;
    margin: auto;
    display: block;
    width: fit-content;
}
.radio input{}
.box-checkout a, .radio input {
    background: #D8A436;
    color: white;
    text-decoration: none;
    font-size: 18px;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
        letter-spacing: 2px;
        border:none
}
.box-end {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 70%;
    margin: auto;
}
.box-left img {
    width: 100%;
}
.box-right hr {
    left: 0;
    display: inline-flex;
}
.box-right {
    text-align: left;
    padding: 25% 0px;
}
.box-checkout {
    width: 60%;
    border: solid;
    margin: auto;
    padding: 5% 0;
}
.box-checkout h3 {
    font-size: 25px;
}
.box-checkout p {
    padding-bottom: 20px;
}
footer{background: #2D2D2D;
    clip-path: polygon(0 0,50% 120px,100% 0,100% 100%,0 100%);
    margin-top: -40px;
    padding-top: 13%;}
.column_foot {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 70%;
    margin: auto;
}
.column_foot h3{color:white}
.column_foot hr{display: table-cell;}
.column_foot ul {
    padding: 0;
}
.column_foot li {
    display: block;
    line-height: 35px;
}
.column_foot a{
  color: white;
font-family: poppins;
text-decoration: none;
font-size: 14px
}
.menu-items {
    text-align: right;
}
input.checkbox {
    display: none;
}
.select hr{display:none}

input[name="fav_language"] {
    accent-color: #d8a436;
    border: red solid 2px;
    padding: 20px;
    width: 20px;
    height: 20px;
}
input[name="fav_language2"] {
  accent-color: #d8a436;
  border: red solid 2px;
  padding: 20px;
  width: 20px;
  height: 20px;
}
input[type="submit"]:hover {
    background: #363636;
}
.button-submit {
    text-align: center;margin-top: 20px
}


/*Mobile*/
@media(max-width:650px){
  form.formtwo {
      display: grid!important;
      grid-template-columns:100%;
  }
  .formtwo .button-submit {
    text-align: center;
    margin-top: 0px;
    margin-left: 0;

  }
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
  height: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  right:0;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  position: absolute;
    top: 70px;
    left: -178px;
    padding-top: 60px;
  background:white;
  height: 100vh;
  width: 100%;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding-left: 50px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}



.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}
/*Content*/
    .banner{
        grid-template-columns: 100%;
        height: 100vh;
    }
.banner h3{
  line-height: 28px;
  font-size: 19px;
}
.banner p{
  text-align: justify;
  width: 80%;
}

.plan img {
    width: 100%;
}
.plan p {
    max-width: 1000%;
    text-align: justify;
  }
  .select{
    grid-template-columns: 100%;
  }
  .select div {
    padding: 0;
}
.select h4 {
    font-size: 20px;
    height: initial;
}
.select hr {
    width: 100%;
    border-top: solid 0.1px #D8A437;
    margin: 40px 0 0 0;
    display: block;
}
.bottom2 {
    grid-template-columns: 50% 50%;
}
.b2 {
    margin-bottom: 62px;
}

.b2 h4 {
    height: 60px;
}
.column_foot {
    width: 90%;
    padding-top: 88px;
}
.column_foot h3 {
    font-size: 16px;
}
.plan p span{text-align: center}

p.pricet {
    text-align: center;
}
.box-end{    grid-template-columns: 100%;}
.box-right{padding: 0}
.banner-content {
    bottom: 0%!important;
    top: 20%;
    position: absolute;
    width: 90%;
    height: inherit;
    display: contents;
}
}
